import { useCallback, useState } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { TypoXS } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import styles from './css.js';

type CoinEachRank = {
  base: number;
  gold: number;
  platinum: number;
  regular: number;
  silver: number;
};

type Group = {
  code: string;
  labels: Label[];
  properties: Property[];
};

type Label = {
  country_code: string;
  title: string;
};

type Property = {
  coin_each_rank: CoinEachRank;
  coin_range: number[];
  coin_range_each_rank: CoinEachRank[];
  id: number;
  name: string;
  prefecture: string;
  thumbnail_url: string;
};

type Props = {
  onCuratedPropertyGroupChange: (groupCode: string) => void;
};

const CuratedPropertyGroupTabs = ({ onCuratedPropertyGroupChange }: Props) => {
  const { lang } = useTranslation();

  const { curatedProperties } = useAppSelector((state) => ({
    curatedProperties: state.curatedProperties,
  }));

  const [selectedGroupCode, setSelectedGroupCode] = useState(
    curatedProperties?.groups[0]?.code || ''
  );

  const onClickTab = (groupCode: string) => {
    setSelectedGroupCode(groupCode);
    onCuratedPropertyGroupChange(groupCode);
  };

  const getGroupTitle = useCallback(
    (labels: Label[]) => {
      return labels?.find((label) => label.country_code === lang)?.title;
    },
    [lang]
  );

  return (
    <div className="curated-group-tabs">
      {curatedProperties?.groups?.map((group: Group) => {
        const isSelected = group?.code === selectedGroupCode;
        const groupTitle = getGroupTitle(group?.labels);

        return (
          <button
            aria-label={`Select group ${groupTitle}`}
            className={classNames('group-tab-button', {
              selected: isSelected,
            })}
            key={group?.code}
            type="button"
            onClick={() => onClickTab(group?.code)}
          >
            <TypoXS
              color={isSelected ? 'black900' : 'black600'}
              text={groupTitle}
            />
          </button>
        );
      })}

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CuratedPropertyGroupTabs;
