import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import JpSectionHeader from '@atoms/JpSectionHeader';
import Swiper from '@atoms/Swiper';
import AreaTabs from '@molecules/AreaTabs';
import CoinTabs from '@molecules/CoinTabs';
import CuratedPropertyGroupTabs from '@molecules/CuratedPropertyGroupTabs';
import PropertySliderItem from '@molecules/PropertySliderItem';
import { Property } from '@services/hafh/types/generated';
import { BREAKPOINT } from '@utils/constants';
import { uuid } from '@utils/utils';
import styles from './css';

const options = {
  breakpoints: {
    [BREAKPOINT.desktopL]: {
      slidesPerView: 4,
    },
    [BREAKPOINT.mobile]: {
      slidesPerView: 3,
    },
    [BREAKPOINT.mobileS]: {
      slidesPerView: 2.1,
    },
  },
  centeredSlides: false,
  loop: false,
  observer: true,
  slidesPerView: 1.5,
  spaceBetween: 16,
};

type Props = {
  campaignId?: string;
  customOptions?: SwiperOptions;
  getPropertiesByArea?: any;
  getPropertiesByCoin?: any;
  hasCoinTabs?: boolean;
  hasCuratedPropertyGroupTabs?: boolean;
  isAreaTabs?: boolean;
  name: string;
  onCuratedPropertyGroupChange?: (groupCode: string) => void;
  properties: Property[];
  sliderType?:
    | 'campaignProperties'
    | 'curatedProperties'
    | 'lp'
    | 'planSelect'
    | 'ranking'
    | null;
  title?: string;
};

const PropertySlider = ({
  campaignId = '',
  customOptions = undefined,
  getPropertiesByArea,
  getPropertiesByCoin,
  hasCoinTabs = false,
  hasCuratedPropertyGroupTabs = false,
  isAreaTabs = false,
  name,
  onCuratedPropertyGroupChange = () => {},
  properties = [],
  sliderType = null,
  title = undefined,
}: Props) => {
  const [swiperUniqueKey, setSwiperUniqueKey] = useState('');

  useEffect(() => {
    setSwiperUniqueKey(uuid());
  }, [properties]);

  return (
    <div className="property-slider">
      {title && <JpSectionHeader heading={title} />}
      {isAreaTabs && <AreaTabs getPropertiesByArea={getPropertiesByArea} />}

      {/* For curated properties by admin panel */}
      {hasCuratedPropertyGroupTabs && (
        <CuratedPropertyGroupTabs
          onCuratedPropertyGroupChange={onCuratedPropertyGroupChange}
        />
      )}

      {hasCoinTabs && <CoinTabs getPropertiesByCoin={getPropertiesByCoin} />}
      <div className="property-slider-container">
        {properties.length > 0 && (
          <Swiper
            hasSwiperButton={true}
            key={swiperUniqueKey}
            name={name + swiperUniqueKey}
            options={customOptions || options}
          >
            {properties.map((property) => (
              <SwiperSlide key={property.name}>
                <PropertySliderItem
                  campaignId={campaignId}
                  property={property}
                  // Swiper has breakpoint then sizeString prop also has each suitable px
                  sizeString={`(max-width: ${BREAKPOINT.mobileS}px) 62.5vw, (max-width: ${BREAKPOINT.mobile}px) 50vw, (max-width: ${BREAKPOINT.desktopL}px) 33vw, (max-width: ${BREAKPOINT.desktopXl}px) 25vw, 20vw`}
                  sliderType={sliderType}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PropertySlider;
